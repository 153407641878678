@import "../src/app/core/variables.scss";
@import "../node_modules/bootstrap/scss/bootstrap.scss";

body {
    font-size: 0.85rem;
    font-family: "Poppins", sans-serif !important;
    color: #3e474f !important;
    display: flex;
    flex-direction: column;
    height: 100vh;
    /* Avoid the IE 10-11 `min-height` bug. */
    // background-color: #f1f0ea !important;
    background-color: #f8f8f8 !important;
}

.card-title {
    font-weight: 600;
}

.minh600 {
    min-height: 600px !important;
}

.btnw215 {
    min-width: 215px !important;
}

.btnh40 {
    height: 40px !important;
}

.btnw100 {
    min-width: 100px !important;
}

.blockquote {
    font-size: 1.3rem !important;
    font-weight: 600 !important;
}

.card-text {
    font-size: 1.2rem !important;
}

.cardbox {
    background: #91c446;
    color: white;
}

.bg-info {
    background: #76a511 !important;
}

// body {
//     background-color: #f8f8f8 !important;
//     margin: 0px auto !important;
//     margin: 0px !important;
//     padding: 0px !important;
// }
.rounded {
    border-radius: 0.5rem !important;
}

.emailboxh {
    min-height: 100% !important;
}

.contactboxh {
    min-height: 100% !important;
}

.form-control {
    padding: 17px !important;
    border-color: #e6e6e7 !important;
    margin-top: 5px !important;
}

.topbar-sticky {
    position: fixed !important;
    top: 0;
    height: 60px;
    z-index: 2;
    background: #ffffff;
    width: 100%;
}

.btn-primary {
    background: #8bc53f !important;
    color: #ffffff !important;
    border-color: #8bc53f !important;
    padding: 13px 0px 13px 0px !important;
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active,
.btn-primary.active,
.open>.dropdown-toggle.btn-primary {
    background: #7eb536 !important;
    border-color: #7eb536 !important;
    padding: 13px 0px 13px 0px !important;
}

.btn-primary:active,
.btn-primary.active {
    background: #7eb536 !important;
    box-shadow: none !important;
    border-color: #7eb536 !important;
    padding: 13px 0px 13px 0px !important;
}

.btn-outline-primary {
    color: #8bc53f !important;
    border-color: #8bc53f !important;
    padding: 13px 0px 13px 0px !important;
}

.btn-outline-primary:hover,
.btn-outline-primary:focus,
.btn-outline-primary:active,
.btn-outline-primary.active,
.open>.dropdown-toggle.btn-outline-primary {
    color: #7eb536 !important;
    background: #ffffff !important;
    border-color: #7eb536 !important;
    padding: 13px 0px 13px 0px !important;
}

.btn-outline-primary:active,
.btn-outline-primary.active {
    color: #ffffff !important;
    background: #7eb536 !important;
    box-shadow: none !important;
    border-color: #7eb536 !important;
    padding: 13px 0px 13px 0px !important;
}

.btn-block {
    display: block;
    width: 100%;
    padding: 10px 0px 10px 0px !important;
}

.w-box {
    width: 1030px !important;
    background: white !important;
    border-radius: 7px !important;
}

.btn-fb {
    color: #fff !important;
    background-color: #3b5998 !important;
    border-color: #3b5998 !important;
}

.btn-gmail {
    color: #fff !important;
    background-color: #de5246 !important;
    border-color: #de5246 !important;
}

i.nav_icons {
    width: 28px !important;
    font-size: 1.4rem !important;
    text-align: center !important;
}

.page-padding {
    padding-left: 40px;
    padding-right: 40px;
}

.persnlinfoicon {
    border: 2px solid #e8e8ec;
    border-radius: 50% !important;
    padding: 12px !important;
    width: 60px !important;
    height: 60px !important;
    text-align: center !important;
    color: white;
}

.persnlinfoicon i {
    color: #e8e8ec;
}

@media only screen and (max-width: 1400px) {
    .page-padding {
        padding-left: 30px;
        padding-right: 30px;
    }
}

body .ui-widget {
    font-size: 0.85rem;
    font-family: "Poppins", sans-serif;
    color: #3e474f;
}

.content {
    flex: 1 0 auto;
    /* Prevent Chrome, Opera, and Safari from letting these items shrink to smaller than their content's default minimum size. */
}

a {
    color: #4e5e79;
}

a:hover {
    text-decoration: none;
    color: #4e5f73;
}

// .form-control {
//     border-radius: 0.2rem;
//     border-width: 2px 2px 2px 2px !important;
//     border-color: $gray-300 $gray-300 $gray-300 $gray-300 !important;
// }
// .form-control {
//     background: transparent !important;
//     border: none !important;
//     border-bottom: 1px solid #DEE2E6 !important;
//     -webkit-box-shadow: none !important;
//     box-shadow: none !important;
//     border-radius: 0 !important;
// }
// .input:focus {
//     -webkit-box-shadow: none !important;
//     box-shadow: none !important;
// }
.rating_star_full {
    /* color: #F9D864; */
    color: #f85959;
}

.rating_star_gray {
    color: #a7b0cf;
}

.text-transparent {
    color: transparent;
}

.table td {
    padding: 7px;
    word-wrap: break-word;
}

.main-link {
    color: $dark !important;
}

.text-strike {
    text-decoration: line-through;
}

.text-strong {
    font-weight: bold;
    color: $dark;
}

.icon-large {
    font-size: 6rem;
}

.icon-small {
    font-size: 1.25rem;
}

.text-home {
    color: #2196f3;
}

.text-office {
    color: #32a457;
}

.text-telemedicine {
    color: #f85959;
}

.background-transparent {
    background-color: transparent;
}

.btn.focus,
.btn:focus {
    outline: 0;
    box-shadow: none;
}

button:focus {
    outline: 0px !important;
}

.width-full {
    width: 100% !important;
}

.ui-autocomplete {
    width: 100% !important;
}

.homepage-header {
    background-repeat: no-repeat;
    background-size: cover;
}

.homepage-form {
    background-color: #61c699;
    margin-bottom: -350px;
    border-radius: 2px;
}

.link {
    cursor: pointer;
    color: #4e5e79 !important;
}

.link:hover {
    text-decoration: underline !important;
}

.ui-datepicker.ui-datepicker-inline {
    border: none !important;
}

.btn-time-slots {
    font-size: 0.9rem;
    width: 8rem !important;
    border-color: #e7e7e7;
    margin: 10px 15px 0px 0px;
}

.btn-time-slot-home {
    background-color: $home;
    color: white;
    padding: 0px 0px;
    font-size: 0.9rem;
    width: 5rem;
}

.btn-time-slot-office {
    background-color: $office;
    color: white;
    padding: 0px 0px;
    font-size: 0.9rem;
    width: 5rem;
}

.btn-time-slot-tele {
    background-color: $telemedicine;
    color: white;
    padding: 0px 0px;
    font-size: 0.9rem;
    width: 5rem;
}

.time-slot-am-pm {
    font-size: 14px;
    vertical-align: top;
    padding-left: 6px;
}

.text-medium {
    color: $medium !important;
}

.text-help {
    color: $gray-600;
    font-size: 13px;
    font-style: italic;
}

.text-sm {
    font-size: 0.8rem;
}

.text-xsm {
    font-size: 0.75rem;
}

.bg-transparent {
    background: transparent;
}

@media only screen and (max-width: 600px) {
    .btn-search-doctor {
        margin-bottom: 15px;
        width: 100%;
        margin-right: 0px;
    }

    .btn-schedule-appointment {
        width: 100%;
    }

    .homepage-form {
        margin-bottom: 0 !important;
    }
}

.bg-medium {
    background-color: $medium;
}

.nav-link.active,
.nav-item.show .nav-link {
    border-width: 0px 0px $nav-tabs-border-width 0px;
}

.nav-link {
    color: $dark;
}

.btn-secondary {
    color: $dark;
    font-weight: 500;
}

.ui-slider .ui-slider-range {
    background-color: $secondary !important;
    border: 0px;
}

.ui-slider .ui-slider-handle {
    background-color: $secondary !important;
    border: 0px !important;
    border-radius: 0px !important;
    width: 8px !important;
}

.ui-slider-horizontal .ui-slider-handle {
    margin-left: -4px !important;
}

.ui-state-active {
    background-color: $selection !important;
    //   background-color:$secondary !important;
}

.ui-rating a .ui-rating-icon {
    font-size: 1.75rem !important;
    color: #f85959 !important;
}

.ui-corner-all {
    border-radius: $border-radius;
}

.ui-inputtext {
    // border-width: 2px 2px 2px 2px !important;
    border-color: $gray-300 $gray-300 $gray-300 $gray-300 !important;
}

.ui-autocomplete-input {
    padding: 0.35rem !important;
}

::placeholder {
    color: $gray-500 !important;
    font-weight: 0 !important;
    font-size: 0.8rem !important;
}

.btn-icon {
    padding: 0px;
    font-size: 1.25rem;
    width: 1.25rem;
}

.btn-icon-sm {
    padding: 0px;
    font-size: 1rem;
    width: 1rem;
}

.btn-icon-text {
    font-size: 1.3rem;
}

.bg-lesslight {
    background-color: $lesslight;
}

a {
    cursor: pointer !important;
}

button {
    cursor: pointer !important;
}

.cursor-hand {
    cursor: pointer !important;
}

.social-login-gmail {
    display: block;
    line-height: 40px;
    border: 1px solid #babacc;
    font-size: 14px;
    background-color: #fff;
    font-style: normal;
    padding: 0 8px 0 54px;
    color: #747487;
    position: relative;
}

.social-login-facebook {
    display: block;
    line-height: 40px;
    border: 1px solid #babacc;
    background-color: #3b5998;
    font-size: 14px;
    font-style: normal;
    padding: 0 8px 0 54px;
    color: #fff;
    position: relative;
}

/*.login-btn-google{
    background-image:url('./assets/images/google_icon.svg');
    background-repeat: no-repeat;
    background-size: 25px 25px;
    background-position: 10px center;
}
.login-btn-facebook{
    background-image: url('./assets/images/facebook_black_logo.svg');
    background-repeat: no-repeat;
    background-size: 25px 25px;
    background-position: 10px center;
}

.login-btn-mac{
    background-image: url('./assets/images/mac-os-logo.svg');
    background-repeat: no-repeat;
    background-size: 25px 25px;
    background-position: 10px center;
}*/

.text-separator {
    //width: 50%;
    height: 1px;
    border: none;
    color: #333;
    /* background-color: #333; */
    background-color: #ddd;
}

.input-Center-text {
    text-align: center;
}

.field-label {
    //font-weight: bold;
    color: $gray-600;
}

.field-label {
    font-weight: 500;
    color: #444 !important;
}

.btncust-secon {
    border-color: #ececec;
    color: #fff;
    padding: 10px 40px 13px 40px;
}

.btncust-secon:hover {
    color: #fff;
    background-color: #f8f8f8;
    padding: 10px 40px 13px 40px;
    border-color: #f8f8f8;
}

.field-value {
    color: $dark;
    font-size: 0.9rem;
}

.title {
    color: $dark;
    font-size: 1.1rem;
    font-weight: bold;
}

.title-lg {
    color: $dark;
    font-size: 1.4rem;
    font-weight: bold;
}

.text-lg {
    color: $dark;
    font-size: 1rem;
}

.card-header h2 button i.fas {
    padding-right: 15px;
    color: #5bda8d;
}

.form-group-bottom-margin {
    margin-bottom: -1rem;
}

.font-icon {
    font-size: 15px;
}

.popover {
    margin-bottom: -0.5rem;
}

.mnustyle>ul.list {
    width: 200px;
    margin: 0;
    padding: 5px;
    list-style: none;
    background: #fff;
    color: #333;
    border-radius: 5px;
    border: 1px solid #ccc;
    box-shadow: 0 0 0px #ccc;
}

.mnustyle>ul.list li {
    padding: 10px;
    border-bottom: solid 1px #ccc;
}

.mnustyle>ul.list li:first-child {
    border-bottom: solid 4px rgb(82, 82, 82);
    font-weight: bold;
}

.mnustyle>ul.list li:last-child {
    border: none;
}

.mnustyle>ul.list li:hover {
    background: #e4e4e4;
}

.btn-pad {
    padding-left: 3rem;
    padding-right: 3rem;
}

.btn-pad-sm {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
}

.btn-success {
    color: white;
}

.double-under-line {
    text-decoration-line: underline;
    text-decoration-style: double;
}

.disable-select {
    user-select: none;
    /* supported by Chrome and Opera */
    -webkit-user-select: none;
    /* Safari */
    -khtml-user-select: none;
    /* Konqueror HTML */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
}

.btn-text {
    padding: 0px;
    border: 0px;
    background: transparent;
}

ul,
li {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

.ql-editor strong {
    font-weight: bold;
}

.btn-xsm {
    padding: 0.15rem 0.8rem;
    font-size: 0.7rem;
    line-height: 1.5;
    border-radius: 0.1rem;
}

.label-text-2 {
    font-size: 1rem;
}

.label-text-3 {
    font-size: 1.2rem;
}

.table {
    margin-bottom: 0px;
    table-layout: fixed;
}

div.action-row:hover {
    background-color: rgba(0, 0, 0, 0.05);
}

// /* width */
// ::-webkit-scrollbar {
//     width: 10px;
//   }
//   /* Track */
//   ::-webkit-scrollbar-track {
//     background: #fff;
//   }
//   /* Handle */
//   ::-webkit-scrollbar-thumb {
//     background: $light;
//     border-radius: 3px;
//   }
//   /* Handle on hover */
//   ::-webkit-scrollbar-thumb:hover {
//     background: $medium;
//     border-radius: 3px;
//   }
.backdrop {
    //background-color: rgba($color: #fff, $alpha: 0.5) !important;
    background-color: transparent !important;
}

.ng-popover {
    bottom: 32%;
    right: 50.8%;
    width: 250px;
    padding: 15px;
    background: #fefefe;
    font-size: 0.875em;
    border-radius: 5px;
    box-shadow: 0 1px 3px #ccc;
    border: 1px solid #ddd;
}

.form-control-sm {
    height: calc(1.5em + 0.5rem + 3px) !important;
}

textarea {
    resize: vertical;
    /* user can resize vertically, but width is fixed */
}

div[summarywidget] p {
    margin-bottom: 0.2rem !important;
}

.app-button {
    border-width: 1px;
    border-style: solid;
    //  border-color: $gray-300;
    border-radius: 0.25rem;
    // background-color: #9bc366;
    color: #fff !important;
    height: 40px !important;
    font-size: 35px !important;
    background: #87b32a !important;
    color: #ffffff !important;
    border-color: #87b32a !important;
}

pre {
    all: unset;
    white-space: pre;
}

.ui-timepicker>div {
    line-height: 19px !important;
}

/*------My Profile-------*/

i.brownicon {
    color: #907a57 !important;
}

.btnabsolute {
    position: absolute !important;
    bottom: 0px !important;
}

.bg-dark {
    background-color: #00220e !important;
}

.text-success {
    color: #87b32a !important;
}

.text-success1 {
    border: 2px solid #87b32a;
}

.video_layer {
    background-color: #5d6b78;
    opacity: 0.8;
    color: white;
    border-radius: 5px;
    height: 242px;
}

/*-------Contacts-------*/

.row div div.input-group input {
    margin-top: 0px !important;
}

.row div.media {
    background-color: #f8f8f8;
    max-width: 100% !important;
    border: 2px solid #f8f8f8;
}

// .row div.media:hover {
//     background-color: #8bc53f !important;
//     color: #ffffff !important;
// }
li.page-item a.page-link {
    color: gray;
}

li.page-item a.page-link:hover {
    background-color: #87b32a;
    color: #ffffff;
}

li.page-item a.page-link:focus {
    background-color: #87b32a;
    color: #ffffff;
}

li.page-item.active a.page-link {
    background-color: #87b32a;
    border-color: #87b32a;
    color: #ffffff;
}

.page-link {
    border-radius: 20px !important;
    background: transparent !important;
    text-align: center !important;
    width: 37px !important;
    height: 37px !important;
    border-radius: 100% !important;
    display: inline-flex !important;
    flex-direction: column !important;
    justify-content: center !important;
    align-items: center !important;
    font-size: 16px !important;
    columns: #333333 !important;
    text-decoration: none !important;
}

/*-------Breadcrumb-------*/

.breadcrumb {
    background-color: #ffffff;
    padding-bottom: 0px;
}

.breadcrumb-item+.breadcrumb-item::before {
    content: "" !important;
}

.breadcrumb-item {
    opacity: 0.3;
}

.breadcrumb-item.active {
    opacity: 1;
}

.breadcrumb-item a {
    color: #333;
    font-size: 16px;
    font-weight: 500;
}

li.breadcrumb-item a span.badge-pill {
    color: #333;
    font-size: 16px;
    font-weight: 500;
}

.breadcrumb li.breadcrumb-item .fa {
    font-weight: normal !important;
}

/*------Dashboard-------*/

.btn-light {
    color: #212529 !important;
    border-color: #eef1f3 !important;
    padding: 13px 20px 13px 20px !important;
}

.btn-light:hover,
.btn-light:focus,
.btn-light:active,
.btn-light.active,
.open>.dropdown-toggle.btn-light {
    color: #212529 !important;
    background: #ffffff !important;
    border-color: #ffffff !important;
    padding: 13px 20px 13px 20px !important;
}

.btn-light:active,
.btn-light.active {
    color: #212529 !important;
    background: #ffffff !important;
    box-shadow: none !important;
    border-color: #ffffff !important;
    padding: 13px 20px 13px 20px !important;
}

.btn-outline-light {
    color: #ffffff !important;
    border-color: #ffffff !important;
    padding: 13px 10px 13px 10px !important;
}

.btn-outline-light:hover,
.btn-outline-light:focus,
.btn-outline-light:active,
.btn-outline-light.active,
.open>.dropdown-toggle.btn-outline-light {
    color: #212529 !important;
    background: #ffffff !important;
    border-color: #ffffff !important;
    padding: 13px 10px 13px 10px !important;
}

.btn-outline-light:active,
.btn-outline-light.active {
    color: #212529 !important;
    background: #ffffff !important;
    box-shadow: none !important;
    border-color: #ffffff !important;
    padding: 13px 10px 13px 10px !important;
}

#sidebar {
    min-width: 250px;
    max-width: 250px;
    background: #00220e;
    color: #fff;
    -webkit-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
    position: relative;
}

a {
    color: #007bff;
    text-decoration: none;
    background-color: transparent;
}

a:hover {
    color: #0056b3;
    text-decoration: underline;
}

a:not([href]):not([tabindex]) {
    color: inherit;
    text-decoration: none;
}

a:not([href]):not([tabindex]):hover,
a:not([href]):not([tabindex]):focus {
    color: inherit;
    text-decoration: none;
}

a:not([href]):not([tabindex]):focus {
    outline: 0;
}

a {
    -webkit-transition: 0.3s all ease;
    -o-transition: 0.3s all ease;
    transition: 0.3s all ease;
    color: #2f89fc;
}

a:hover,
a:focus {
    text-decoration: none !important;
    outline: none !important;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.wrapper {
    width: 100%;
}

#sidebar .h6 {
    color: #fff;
}

#sidebar.active {
    margin-left: -250px;
}

#sidebar.active .custom-menu {
    margin-right: -50px;
}

#sidebar h1 {
    margin-bottom: 20px;
    font-weight: 700;
    font-size: 20px;
}

#sidebar ul.components {
    padding: 0;
}

@media (max-width: 991.98px) {
    #sidebar {
        margin-left: -250px;
    }

    #sidebar.active {
        margin-left: 0;
    }

    #sidebar .custom-menu {
        margin-right: -60px !important;
        top: 10px !important;
    }
}

#sidebar .custom-menu {
    display: inline-block;
    position: absolute;
    top: 10px;
    right: 0;
    margin-right: -50px;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
}

@media (prefers-reduced-motion: reduce) {
    #sidebar .custom-menu {
        -webkit-transition: none;
        -o-transition: none;
        transition: none;
    }
}

#sidebar .custom-menu .btn.btn-primary {
    background: transparent;
    border-color: transparent;
}

#sidebar .custom-menu .btn.btn-primary i {
    color: black;
    font-size: 24px;
}

#sidebar .custom-menu .btn.btn-primary:hover,
#sidebar .custom-menu .btn.btn-primary:focus {
    background: transparent !important;
    border-color: transparent !important;
}

#sidebar ul li {
    font-size: 16px;
}

#sidebar ul li>ul {
    margin-left: 10px;
}

#sidebar ul li>ul li {
    font-size: 14px;
}

#sidebar ul li a {
    padding: 15px 30px;
    display: block;
    color: rgba(255, 255, 255, 0.6);
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

#sidebar ul li a:hover {
    color: #fff;
    background: #426528;
    border-bottom: 1px solid #426528;
}

#sidebar ul li.active>a {
    background: transparent;
    color: #fff;
}

#sidebar ul li.active>a:hover {
    background: #426528;
    border-bottom: 1px solid #426528;
}

.contact_card_selected {
    // background-color: #8abd3f !important;
    // color: #ffffff !important;
    color: #333333 !important;
    border: 2px solid #8abd3f !important;
}

.contact_card {
    cursor: pointer;
}

.contact_letter_selected {
    background-color: #8abd3f !important;
    color: #ffffff !important;
}

.contact_letter_disabled {
    background-color: #f8f8f9f7 !important;
    color: #333333 !important;
    // background-color: darkgrey !important;
    // color: #ffffff !important;
}

/*------Dashboard-------*/

.dashbox1 {
    background: #8ac748 !important;
    padding: 0px 15px !important;
}

.dashbox2 {
    background: #43ac69 !important;
    padding: 0px 15px !important;
}

.dashbox3 {
    background: #65bd4c !important;
    padding: 0px 15px !important;
}

.dashbox4 {
    background: #37a0a4 !important;
    padding: 0px 15px !important;
}

.dashbox5 {
    background: #0f8bad !important;
    padding: 0px 15px !important;
}

.dashbox6 {
    background: #1a77a2 !important;
    padding: 0px 15px !important;
}

.dashbox7 {
    background: #50b79a !important;
    padding: 0px 15px !important;
}

.dashbox8 {
    background: #144e76 !important;
    padding: 0px 15px !important;
}

.dashbox9 {
    background: #186293 !important;
    padding: 0px 15px !important;
}

.dashbox10 {
    background: #5f87a1 !important;
    padding: 0px 15px !important;
}

.dashbox11 {
    background: #233b4b !important;
    padding: 0px 15px !important;
}

.totalrecord {
    border-left: 7px solid #8abd3f;
    border-left: 7px solid #d0f599;
    padding-left: 15px;
}

.spaceused {
    border-left: 7px solid #a0c97a;
    border-left: 7px solid #abe15c;
    padding-left: 15px;
}

// .myjournal-col {
//     background: #8ac748 !important;
//     padding: 0px 15px !important;
// }

// .videomessage-col {
//     background: #43ac69 !important;
//     padding: 0px 15px !important;
// }

// .emailmessage-col {
//     background: #65bd4c !important;
//     padding: 0px 15px !important;
// }

// .obituary-col {
//     background: #37a0a4 !important;
//     padding: 0px 15px !important;
// }

// .virtualvault-col {
//     background: #0f8bad !important;
//     padding: 0px 15px !important;
// }

// .passwordvault-col {
//     background: #1a77a2 !important;
//     padding: 0px 15px !important;
// }

// .inbox-col {
//     background: #50b79a !important;
//     padding: 0px 15px !important;
// }

// .adminaccess-col {
//     background: #144e76 !important;
//     padding: 0px 15px !important;
// }

// .contacts-col {
//     background: #186293 !important;
//     padding: 0px 15px !important;
// }

.totalrecord {
    border-left: 7px solid #d0f599;
    padding-left: 15px;
}

.spaceused {
    border-left: 7px solid #abe15c;
    padding-left: 15px;
}

.spaceremain {
    border-left: 7px solid #ceccbd;
    padding-left: 15px;
}

.btn-progress {
    height: 5px;
    position: absolute;
    bottom: 16px;
    background-color: red;
    opacity: 0.5;
}

div app-icon-button div button i.text-primary {
    color: #212529 !important;
}

div app-icon-button a.btnw118 {
    min-width: 118px !important;
}

div app-icon-button a.btnw215 {
    min-width: 210px !important;
}

.table .thead-dark th {
    color: #fff !important;
    background-color: yellowgreen !important;
    border-color: yellowgreen !important;
}

.email-read {
    background-color: #f8f8f9f7 !important;
}

.email_box {
    background: #8bc53f !important;
    padding: 7px 0px !important;
    min-width: 35px !important;
    height: 35px !important;
    border-radius: 5px !important;
    color: #ffffff !important;
    font-weight: bold !important;
    margin-right: 12px !important;
    text-align: center !important;
}

.btn-light:hover {
    color: #212529 !important;
    background-color: #eef1f3 !important;
    border-color: #f8f9fa !important;
}

.table {
    width: 100%;
    margin-bottom: 0rem !important;
    color: #212529;
}

/***************FileUpload***************/

.uploaded {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    border-bottom: 1px solid lightgray;
    padding: 15px 0px;
}

.uploaded .file {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.uploaded .file .file_name {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: baseline;
    line-height: 0;
    color: #0c3214;
    font-size: 18px;
    letter-spacing: 1.5px;
}

table tr td app-icon-button div.btn-primary {
    padding: 7px 20px !important;
}

table tr td app-icon-button div.btn-primary:hover {
    padding: 7px 20px !important;
}

table tr td app-icon-button button.p-0 {
    color: white !important;
}

.h2,
h2 {
    font-size: 1.5rem !important;
}

div.input-group div.input-group-append button.btn-secondary {
    background-color: #8abd3f !important;
    border: #8abd3f !important;
}

div.input-group div.input-group-append button.btn-secondary:hover {
    background-color: #7eb536 !important;
    border: #7eb536 !important;
}

.input-group-append .btn,
.input-group-prepend .btn {
    position: relative;
    z-index: 0 !important;
}

.schedulewbg {
    width: 70px;
    height: 70px;
    text-align: center;
    color: #666 !important;
    font-size: 32px !important;
    padding: 11px 0px !important;
    background-color: #fff !important;
    border-radius: 50px;
}

.adminusericon {
    color: #666 !important;
    font-size: 24px !important;
}

.user-selection {
    position: fixed !important;
    right: 90px !important;
    top: 4px !important;
}

// .badge {
//     position: relative !important;
//     top: -10px !important;
//     right: 75px !important;
// }
.badgenumber {
    position: relative !important;
    top: -10px !important;
    right: 75px !important;
}

.nav-pills .nav-link {
    border-radius: 0.25rem;
    background-color: #f8f8f8;
    margin-right: 10px;
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
    color: #fff !important;
    background-color: #8abd3f !important;
}

app-passwordvaultlist div div a.btn-outline-primary {
    padding: 15px 0px 15px 0px !important;
}

app-passwordvaultlist div div a.btn-outline-primary:hover {
    padding: 15px 0px 15px 0px !important;
}

// app-securityquestion div div app-icon-button div.btn-primary{
//     padding: 8px 15px !important;
//     color:#ffffff !important;
// }
// app-securityquestion div div app-icon-button div.btn-primary:hover{
//     padding: 8px 15px !important;
//     color:#ffffff !important;
// }
body .ui-multiselect {
    background: #ffffff;
    border: 1px solid #e6e6e6 !important;
    padding: 0.429em 83px 0.11em 5px;
    font-size: 16px !important;
    /* padding-right: 2em; */
    color: #333333;
    /* line-height: 2; */
    margin-bottom: 9px;
    /* padding-right: 2px; */
    letter-spacing: 1px;
    transition: border-color 0.2s;
    height: 54px !important;
    padding: 4px 50px4px 50px 0px 9px !important;
}

body .ui-multiselect .ui-multiselect-trigger {
    margin-right: 9px;
    color: #b6b6b6;
}

.btn-outline-success {
    color: #8bc53f !important;
    background-color: transparent;
    border-color: #8bc53f !important;
}

.btn-outline-success:hover {
    color: #fff !important;
    background-color: #8bc53f !important;
    border-color: #8bc53f !important;
}

td.inboxicons [class*="fal fa-"] {
    border-radius: 50%;
    color: gray;
    display: inline-block;
    height: 36px;
    line-height: 33px;
    width: 36px;
    font-size: 18px;
    text-align: center;
    border: 1px solid gray;
}

.dashroundicons [class*="fal fa-"] {
    border-radius: 50%;
    color: white;
    display: inline-block;
    height: 42px;
    line-height: 41px;
    width: 42px;
    font-size: 22px;
    text-align: center;
    border: 1px solid rgba(255, 255, 255, 0.5);
}

.labelicons [class*="fal fa-"] {
    border-radius: 50%;
    color: white;
    display: inline-block;
    height: 7rem;
    line-height: 3rem;
    width: 7rem;
    font-size: 5rem;
    text-align: center;
    background: white;
}

.ui-multiselected-item-token {
    margin-top: -2px !important;
    background: #007ad9 !important;
}

.preobituary {
    font-family: inherit !important;
    font-size: inherit !important;
    color: inherit !important;
}

body .ui-multiselect-panel .ui-multiselect-items .ui-multiselect-item.ui-state-highlight {
    color: #333;
    background-color: transparent;
}

.swal-icon--warning {
    border-color: #df4740 !important;
}

.swal-text {
    font-size: 16px;
    text-align: center !important;
    color: #333 !important;
    padding: 0 34px !important;
}

.swal-title {
    font-size: 22px !important;
    color: #333 !important;
}

.swal-icon--warning__body,
.swal-icon--warning__dot {
    background-color: #df4740 !important;
}

.swal-footer {
    text-align: center !important;
    padding-bottom: 22px !important;
}

.swal-button--cancel {
    margin-right: 10px !important;
}

.font-cls {
    font-size: 23px !important;
    margin-right: -11px;
}

.ui-progressbar-value {
    background-color: #76a511 !important;
}

.rvidoicon [class*="fal fa-"] {
    border-radius: 50%;
    color: #51565a;
    display: inline-block;
    height: 7rem;
    line-height: 7rem;
    width: 7rem;
    font-size: 5rem;
    text-align: center;
    background: #f8f8f8;
}

/*-----------------------All Inline Styles------------------------*/

.darkgren {
    background-color: #0a3824 !important;
}

.indx_bwhiteh95 {
    padding: 0px !important;
    margin: 0px !important;
    height: 95vh !important;
    background-color: white !important;
    text-align: center !important;
}

.indx_mtop {
    margin-top: calc(50vh - 150px) !important;
}

.alrt_fsize15 {
    font-size: 1.5rem !important;
}

.btn_bcolorgry {
    color: #959595 !important;
}

.contnt_h60 {
    height: 60px !important;
}

.contnt_pfixd {
    margin-bottom: 5px !important;
    margin-top: 7px !important;
    position: fixed !important;
    top: 0 !important;
    left: calc(100% - 75px) !important;
    z-index: 10 !important;
}

.contnt_p15 {
    padding: 0px 15px 0px 15px !important;
    height: 320px !important;
    overflow-y: auto !important;
    overflow-x: hidden !important;
}

.contnt_minh3 {
    min-height: 3px !important;
}

.contnt_plft {
    padding-left: -80px !important;
}

.contnt_h40 {
    height: 40px !important;
}

.contnt_fsizew {
    font-size: 1.2rem !important;
    font-weight: bold !important;
}

.contnt_pfixdbcolor {
    background-color: rgba(0, 0, 0, 0.45) !important;
    position: fixed !important;
    top: 00px !important;
    left: calc(50vw - 75px) !important;
    z-index: 9999999 !important;
}

.contnt_mtop {
    margin-top: calc(40vh - 150px) !important;
}

.dialg_pfixd {
    position: fixed !important;
    margin-left: 90% !important;
    z-index: 1000 !important;
}

.dialg_pfixdt0 {
    position: fixed !important;
    top: 00px !important;
    left: calc(50vw - 60px) !important;
    z-index: 9999999 !important;
}

.dialg_fsize24 {
    font-size: 24px !important;
}

.dialg_wdth10 {
    width: 10px !important;
}

.dialg_h3 {
    height: 3px !important;
}

.dialg_fsize3 {
    font-size: 3rem !important;
}

.dialg_h30 {
    height: 30px !important;
}

.dialg_minh {
    min-height: 50px !important;
}

.fileuplod_pabslte {
    position: absolute !important;
    bottom: 0px !important;
}

.gnric_fsizecolor {
    font-size: 5rem !important;
    color: #999 !important;
}

.gnric_fsize {
    font-size: 5rem !important;
}

.gnric_colorg6 {
    color: #666 !important;
}

.gnric_colorg9 {
    color: #999 !important;
}

.gnric_maxw {
    max-width: 400px !important;
}

.gnric_mtop {
    margin-top: calc(35vh - 150px) !important;
}

.mster_pfixdbgren {
    background-color: #51a351 !important;
    position: fixed !important;
    top: 0px !important;
    left: calc(40vw - 150px) !important;
    right: calc(40vw - 150px) !important;
    text-align: center !important;
    z-index: 9999999 !important;
}

.mster_pfixd {
    margin-top: 7px !important;
    position: fixed !important;
    top: 0 !important;
    right: calc(100% - 60px) !important;
    z-index: 10 !important;
}

.mster_preltive {
    position: relative !important;
    left: 84px !important;
    z-index: 7 !important;
}

.mster_wdth {
    object-fit: none !important;
    width: 100% !important;
    height: 620px !important;
}

.mster_brdius {
    border-radius: 7px !important;
    border: 1px dashed #cecece !important;
}

.mster_ptopmtop {
    position: absolute;
    left: 0;
    background-color: white;
}

.mster_overflwh {
    overflow: hidden !important;
    // height: calc(100vh) !important;
    height: calc(100vh - 55px);
}

.mster_brdiusbg {
    border-radius: 0px 0px 7px 7px !important;
    background: #f8f8f8 !important;
}

.mster_colorg {
    color: #cccccc !important;
}

.mster_fsize4 {
    font-size: 4rem !important;
    color: #999 !important;
}

.mster_w0 {
    width: 0rem !important;
}

.mster_fsize1 {
    font-size: 1.4rem !important;
}

.mster_h50 {
    height: 50px !important;
}

//.mster_zindx{z-index: 1000 !important;}         //commented to fix mask overlay issue in sidebar menu

.sectn_fsize {
    font-size: 0.9rem !important;
}

.switch_fsize {
    margin-top: -0rem !important;
    font-size: 15px !important;
}

.adminaces_pl24 {
    padding-left: 24px !important;
}

.adminaces_bcolor {
    background: #f5f6fa !important;
}

.select_fsizemtop {
    font-size: 14px !important;
    display: inline-block !important;
    margin-top: 4px !important;
}

.interphne_w24 {
    width: 24px !important;
    top: 1px !important;
    left: 5px !important;
    margin-bottom: 3px !important;
}

.interphne_mtop {
    margin-left: 0.1em !important;
}

.dwnld_wbrder {
    width: 100% !important;
    border: 1px dashed gray !important;
    margin: 15px !important;
    border-radius: 5px !important;
}

.dwnld_w12 {
    width: 12rem !important;
}

.profle_wh {
    width: 150px !important;
    height: 150px !important;
}

.profle_minw {
    min-width: 300px !important;
}

.profle_minw170 {
    min-width: 170px !important;
}

.email_fsize {
    font-size: 1.7rem !important;
}

.email_wdth {
    width: 105% !important;
}

.email_minh {
    min-height: 234px !important;
}

.uservrifctn_ovrflow {
    height: 0px !important;
    width: 0px !important;
    overflow: hidden !important;
}

.mesages_floatr {
    float: right !important;
    width: 25px !important;
}

.mesages_prelatve {
    position: relative !important;
    top: -2px !important;
}

.mesages_fsize {
    font-size: 18px !important;
}

.mesages_brderbtm {
    border-bottom: 1px solid #f2f1f1 !important;
}

.mesages_py1 {
    padding: 1px 0px !important;
}

.paymnt_bcolor {
    background: #f8f8f8 !important;
}

.paymnt_txtalign {
    text-align: end !important;
}

.msecurityq_ovrflowx {
    white-space: nowrap !important;
    flex-wrap: nowrap !important;
    overflow-x: auto !important;
    overflow-y: hidden !important;
}

.entitycntact_bradius {
    border-radius: 20px !important;
}

.jurnl_pabslute {
    position: absolute !important;
    top: 15px !important;
    right: 15px !important;
}

.jurnl_minh {
    min-height: 200px !important;
}

.jurnl_lineh {
    width: 80% !important;
    line-height: 24px !important;
}

.jurnl_fsize {
    font-size: 1.2rem !important;
}

.jurnl_fsize16 {
    font-size: 16px !important;
}

.jurnlb_h3 {
    max-width: 100% !important;
    height: 200px !important;
}

.prsnalizj_bradius {
    background: #f8f8f8 !important;
    padding: 17px !important;
    border-radius: 4px !important;
}

.prsnalizj_fsize19 {
    padding-left: 10px !important;
    font-size: 19px !important;
    margin-bottom: 8px !important;
}

.prsnalizj_mr11 {
    margin-right: 11px !important;
}

.prsnalizj_mb6 {
    margin-bottom: 6px !important;
}

.prsnalizj_txtl {
    text-align: left !important;
}

.paswrdv_minh47 {
    min-height: 47px !important;
}

.paswrdv_minh {
    min-height: 47px !important;
}

.paswrdv_fsize14 {
    margin: 10px !important;
    font-size: 14px !important;
    padding: 10px 20px !important;
}

.paswrdv_prlatveh {
    height: 9rem !important;
    position: relative !important;
    top: 13px !important;
}

.vido_h1 {
    height: 116px !important;
}

.vido_h3 {
    height: 243px !important;
}

.mvido_prelatve {
    width: 100% !important;
    height: 0 !important;
    padding-bottom: 50% !important;
    position: relative !important;
    overflow: hidden !important;
}

.mvido_pabslute1 {
    width: 100% !important;
    height: auto !important;
    position: absolute !important;
    top: 50% !important;
    transform: translateY(-66%) !important;
}

.mvido_pabslute2 {
    width: 100% !important;
    height: auto !important;
    position: absolute !important;
    top: 50% !important;
    padding-bottom: 25% !important;
    transform: translateY(-50%) !important;
}

.vvault_bradius4 {
    border-radius: 4px !important;
    cursor: pointer !important;
    border: dashed 1.7px #e7e7ec !important;
    text-align: center !important;
    /* border-spacing: 375px; */
    margin-left: 13px !important;
    margin-top: 7px !important;
}

.vvault_bgren {
    margin: 10px !important;
    background: #8bc53f !important;
    font-size: 14px !important;
    padding: 10px 20px !important;
    cursor: pointer !important;
}

.vvault_txtleft {
    text-align: left !important;
    margin: 10px !important;
    font-size: 14px !important;
    padding: 10px 20px !important;
    cursor: pointer !important;
}

.vvault_prelatve {
    min-height: 47px !important;
    z-index: 1 !important;
    position: relative !important;
    top: 50px !important;
}

.vvault_fsize4 {
    font-size: 4rem !important;
    text-align: center !important;
    color: #888 !important;
}

.vvault_pb11 {
    padding-bottom: 11px !important;
}

.obtray_dsplyb {
    display: inline-block !important;
}

.rusageb_minh {
    min-height: 32px !important;
}

.rusageb_wdth {
    width: 100px !important;
}

.rusageb_minh {
    min-height: 32px !important;
}

.rusageb_minh {
    min-height: 32px !important;
}

.rusageb_minh {
    min-height: 32px !important;
}

.two_fsize {
    font-size: 20px !important;
}

/*-----------------------All Inline Style End-----------------------*/

:host ::ng-deep .p-inputswitch .p-inputswitch-slider:before {
    background-color: #fff !important;
    height: 1.25em !important;
    width: 1.25em !important;
    left: 0.25em !important;
    bottom: 0.25em !important;
    border-radius: 50% !important;
    transition: 0.2s !important;
}

:host ::ng-deep body span.p-inputswitch-slider {
    background-color: #28a745 !important;
}

:host ::ng-deep body .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
    background-color: #28a745 !important;
}

:host ::ng-deep body span.p-inputswitch-slider {
    background-color: #28a745 !important;
}

body .ui-inputswitch.ui-inputswitch-checked .ui-inputswitch-slider {
    background-color: #28a745 !important;
}

.vh-88 {
    min-height: 88vh !important;
}

.primary-button-properties {
    padding: 13px 0px 13px 0px !important;
}

:host ::ng-deep .banner {
    z-index: 1 !important;
}

.swal2-container {
    z-index: 100000000000 !important;
}

.docupricingbtn {
    background-color: #7166e0 !important;
    border-color: #7166e0 !important;
    color: white !important;
    padding: 13px 10px 13px 10px !important;
}

.btn-secondary-doc {
    background-color: #6e787f !important;
    border-color: #6e787f !important;
    color: white !important;
    padding: 13px 10px 13px 10px !important;
}

// Admin Portal CSS/

.adminsidebar {
    background: #8ac748 !important;
    padding: 0px 15px !important;
}


.btn-outline-primary:not(:disabled):not(.disabled).active,
.btn-outline-primary:not(:disabled):not(.disabled):active,
.show>.btn-outline-primary.dropdown-toggle {
    color: #fff !important;
    background-color: #007bff !important;
    border-color: #007bff !important;
}

.drop-shadow {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
}

.logo-container {
    position: absolute !important;
    top: 75% !important;
    right: 23px !important;
    transform: translateY(-50%) !important;
}

.green {
    color: #19a974 !important;
    font-size: 22px !important;
}


#toast-error-msg {
    background: #f8d7da !important;
    border: solid #f5c6cb !important;
    border-width: 1px !important;
    color: #721c24 !important;
}