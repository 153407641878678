$font-size-base: 1rem;

$medium: #555e69;
$light: #d7d9db;
$lesslight: #f5f6fa;
$dark: #3e474f;
$primary: #2196f3;
$success: #32a457;
$danger: #ef5350;
$secondary: #f9d864;
$white: #ffffff;

$telemedicine: #4e5e79;
$home: #2196f3;
$office: #61c699;

$border-radius: 0.1rem;
$border-radius-lg: 0.1rem;
$border-radius-sm: 0.1rem;

$headings-color: #444;
$headings-font-weight: 400;
$h1-font-size: $font-size-base * 1.5 !default;
$h2-font-size: $font-size-base * 1.4 !default;
$h3-font-size: $font-size-base * 1.3 !default;
$h4-font-size: $font-size-base * 1.2 !default;
$h5-font-size: $font-size-base * 1.1 !default;
$h6-font-size: $font-size-base !default;
$headings-margin-bottom: 0.2rem;

$nav-tabs-border-width: 2px;
$nav-tabs-link-active-border-color: transparent transparent $primary transparent;
$nav-tabs-link-active-bg: transparent;

$label-margin-bottom: 0.1rem !default;

$modal-md: 600px !default;

$selection: #54c378;

$emailcolor: #65bd4c;
$emailtextcolor: #ffffff;
$videocolor: #43ac69;
$videotextcolor: #ffffff;
$myjournal: #8ac748;
$myjournaltext: #ffffff;
$adminaccess: #144e76;
$adminaccesstextcolor: #ffffff;
$passwordvalut: #1a77a2;
$lastwill: #5f87a1;
$obituary: #37a0a4;
$obituarytext: #ffffff;
$virtualvalut: #37a0a4;
$contact: #186293;
$contacttext: #ffffff;


//For Admin 
$companycolor : #8AC748;
$companytext: #ffffff;

@mixin button-colors($color) {
    :host ::ng-deep .btn-primary {
        background-color: $color !important;
        border-color: $color !important;
    }

    :host ::ng-deep .btn-outline-primary {
        border-color: $color !important;
        color: $color !important;
    }

    :host ::ng-deep .admin_access_card_selected {
        border-color: $color !important;
    }

    :host ::ng-deep .admin-access-left-card,
    :host ::ng-deep .btn.btn-secondary,
    :host ::ng-deep .contact-create-card,
    :host ::ng-deep .nav-item.nav-link.active,
    :host ::ng-deep .btn-outline-primary.active,
    :host ::ng-deep .email_box,
    :host ::ng-deep .obituary-card,
    :host ::ng-deep .journal-card,
    :host ::ng-deep .password-vault-card {
        background: $color !important;
    }

    :host ::ng-deep .btn-outline-primary_email_message {
        border-color: $emailcolor !important;
        color: $color !important;
    }

    :host ::ng-deep .btn-outline-primary_email_message.active {
        background-color: $emailcolor !important;
        color: $emailtextcolor !important;
    }

    :host ::ng-deep .btn-secondary_email_message {
        background-color: $emailcolor !important;
        color: $emailtextcolor !important;
    }

    :host ::ng-deep .contact_card_selected_email_message {
        border-color: $emailcolor !important;
    }

    :host ::ng-deep .contact_letter_selected_email_message {
        background-color: $emailcolor !important;
        color: $emailtextcolor !important;
    }

    :host ::ng-deep .contact-detail-card_email_message {
        background: $emailcolor;
    }

    :host ::ng-deep .text-success1_email_message {
        border-color: $emailcolor !important;
    }

    :host ::ng-deep .btn-primary_email_message {
        background-color: $emailcolor !important;
        border-color: $emailcolor !important;
        color: $emailtextcolor !important;
    }

    :host ::ng-deep .btn-outline-primary_admin_access {
        border-color: $adminaccess !important;
        color: $color !important;
    }

    :host ::ng-deep .btn-outline-primary_admin_access.active {
        background-color: $adminaccess !important;
        color: $adminaccesstextcolor !important;
    }

    :host ::ng-deep .btn-secondary_admin_access {
        background-color: $adminaccess !important;
        color: $adminaccesstextcolor !important;
    }

    :host ::ng-deep .contact_card_selected_admin_access {
        border-color: $adminaccess !important;
    }

    :host ::ng-deep .contact_letter_selected_admin_access {
        background-color: $adminaccess !important;
        color: $adminaccesstextcolor !important;
    }

    :host ::ng-deep .contact-detail-card_admin_access {
        background: $adminaccess;
    }

    :host ::ng-deep .text-success1_admin_access {
        border-color: $adminaccess !important;
    }

    :host ::ng-deep .btn-primary_admin_access {
        background-color: $adminaccess !important;
        border-color: $adminaccess !important;
        color: $adminaccesstextcolor !important;
    }

    :host ::ng-deep .btn-outline-primary_trustee_access.active {
        color: $adminaccesstextcolor !important;
    }

    :host ::ng-deep .btn-outline-primary_video_message {
        border-color: $videocolor !important;
        color: $color !important;
    }

    :host ::ng-deep .btn-outline-primary_video_message.active {
        background-color: $videocolor !important;
        color: $videotextcolor !important;
    }

    :host ::ng-deep .btn-secondary_video_message {
        background-color: $videocolor !important;
        color: $videotextcolor !important;
    }

    :host ::ng-deep .contact_card_selected_video_message {
        border-color: $videocolor !important;
    }

    :host ::ng-deep .contact_letter_selected_video_message {
        background-color: $videocolor !important;
        color: $videotextcolor !important;
    }

    :host ::ng-deep .contact-detail-card_video_message {
        background: $videocolor;
    }

    :host ::ng-deep .text-success1_video_message {
        border-color: $videocolor !important;
    }

    :host ::ng-deep .btn-primary_video_message {
        background-color: $videocolor !important;
        border-color: $videocolor !important;
        color: $videotextcolor !important;
    }

    :host ::ng-deep .btn-outline-primary_email_message {
        border-color: $passwordvalut !important;
        color: $passwordvalut !important;
    }

    :host ::ng-deep .btn-outline-primary_obituary {
        border-color: $obituary !important;
        color: $color !important;
    }

    :host ::ng-deep .btn-outline-primary_obituary.active {
        background-color: $obituary !important;
        color: $obituarytext !important;
    }

    :host ::ng-deep .btn-secondary_obituary {
        background-color: $obituary !important;
        color: $obituarytext !important;
    }

    :host ::ng-deep .contact_card_selected_obituary {
        border-color: $obituary !important;
    }

    :host ::ng-deep .contact_letter_selected_obituary {
        background-color: $obituary !important;
        color: $obituarytext !important;
    }

    :host ::ng-deep .contact-detail-card_obituary {
        background: $obituary;
    }

    :host ::ng-deep .text-success1_obituary {
        border-color: $obituary !important;
    }

    :host ::ng-deep .btn-primary_obituary {
        background-color: $obituary !important;
        border-color: $obituary !important;
        color: $obituarytext !important;
    }

    :host ::ng-deep .btn-outline-primary_my_journal {
        border-color: $myjournal !important;
        color: $color !important;
    }

    :host ::ng-deep .btn-outline-primary_my_journal.active {
        background-color: $myjournal !important;
        color: $myjournaltext !important;
    }

    :host ::ng-deep .btn-secondary_my_journal {
        background-color: $myjournal !important;
        color: $myjournaltext !important;
    }

    :host ::ng-deep .contact_card_selected_my_journal {
        border-color: $myjournal !important;
    }

    :host ::ng-deep .contact_letter_selected_my_journal {
        background-color: $myjournal !important;
        color: $myjournaltext !important;
    }

    :host ::ng-deep .contact-detail-card_my_journal {
        background: $myjournal;
    }

    :host ::ng-deep .text-success1_my_journal {
        border-color: $myjournal !important;
    }

    :host ::ng-deep .btn-primary_my_journal {
        background-color: $myjournal !important;
        border-color: $myjournal !important;
        color: $myjournaltext !important;
    }

    :host ::ng-deep .btn-outline-primary_contacts {
        border-color: $contact !important;
        color: $color !important;
    }

    :host ::ng-deep .btn-outline-primary_contacts.active {
        background-color: $contact !important;
        color: $contacttext !important;
    }

    :host ::ng-deep .btn-secondary_contacts {
        background-color: $contact !important;
        color: $contacttext !important;
    }

    :host ::ng-deep .contact_card_selected_contacts {
        border-color: $contact !important;
    }

    :host ::ng-deep .contact_letter_selected_contacts {
        background-color: $contact !important;
        color: $contacttext !important;
    }

    :host ::ng-deep .contact-detail-card_contacts {
        background: $contact;
    }

    :host ::ng-deep .contact-detail-card_trustee_access {
        background: $contact;
    }

    :host ::ng-deep .contact-detail-card_virtual_vault {
        background: $virtualvalut;
    }

    :host ::ng-deep .text-success1_virtual_vault {
        border-color: $virtualvalut !important;
    }

    :host ::ng-deep .contact_card_selected_virtual_vault {
        border-color: $virtualvalut !important;
    }

    :host ::ng-deep .btn-primary_virtual_vault {
        background: $virtualvalut !important;
        color: #ffffff !important;
        border-color: $virtualvalut !important;
    }

    //For Admin Module Screens

    :host ::ng-deep .contact-detail-card_add_company {
        background: $companycolor;
    }

    :host ::ng-deep .contact-detail-card_companies {
        background: $companycolor;
    }

    :host ::ng-deep .contact-detail-card_company_user {
        background: $companycolor;
    }

    :host ::ng-deep .btn-outline-primary_add_company {
        border-color: $companycolor !important;
        color: $color !important;
    }

    :host ::ng-deep .btn-outline-primary_add_company.active {
        background-color: $companycolor !important;
        color: $companytext !important;
    }

    :host ::ng-deep .btn-secondary_admin {
        background-color: $companycolor !important;
        color: $companytext !important;
    }

}